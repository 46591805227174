<template>
  <div>
    <div class="modal-edit-user-content">
      <div
        id="edit-user-modal"
        tabindex="-1"
        aria-labelledby="edit-user-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered dialog-edit-user">
          <div class="modal-content">
            <ValidationObserver>
              <div class="modal-body text-start">
                <div>
                  <div class="row d-flex justify-content-center">
                    <p class="text-center" style="font-weight: bold">
                      Bot Information
                    </p>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">Email</div>
                    <div class="col-12 input-group">
                      <input
                        class="form-control"
                        type="text"
                        v-model="email"
                        placeholder=""
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-6 mb-2">Password</div>
                    <div class="col-12 input-group">
                      <input
                        class="form-control"
                        type="text"
                        v-model="password"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 mb-2">NG-Word</div>
                    <div class="col input-group">
                      <textarea class="w-100 form-control" v-model="ngword" placeholder="NG-Word"></textarea>
                    </div>
                  </div>
                  <div class="button-group">
                    <button type="button" class="btn background-C4C4C4 me-2" @click="handleCancel">Cancel
                    </button>
                    <button type="button" class="btn btn-warning text-white float-end" @click="updateNgWord">Save
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Bot",
  components: {},
  data() {
    return {
      email: '1',
      password: '2',
      ngword: '',
      oldngword: '',
    };
  },
  created() {
    this.getBotInfor();
  },
  watch: {
    selectedPm() {
      this.showErrorNotSelectPM = false;
    },
    selectedCustomer() {
      this.showErrorNotSelectCustomer = false;
    },
    selectedBrse() {
      this.showErrorNotSelectBrseComtor = false;
    },
  },
  methods: {
    async getBotInfor() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("bot/getBotInfo", {})
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.email = response.email;
          this.ngword = response.ngword;
          this.password = response.pasword;
          this.oldngword = response.ngword;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    async updateNgWord() {
      const body = {
        ngWord: this.ngword,
      }
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("bot/updateNgWord", body)
        .then(() => {
          this.$root.$refs.loading.finish();
          this.oldngword = this.ngword;
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    async handleCancel() {
      this.ngword = this.oldngword;
    }
  },
};
</script>

<style scoped lang='scss'>
@import "@/assets/scss/projects/bot.scss";
</style>
